import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from './services/events.service';
import { PreferencesService } from './services/preferences.service';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  username: string = 'Gast';

  activeRoute: string = '';

  public appPages : any = [
    { title: 'Stöbern', url: '/home', icon: '', disabled: false },
    { title: 'Suche', url: '/search/blank', icon: '', disabled: false },
    //{ title: 'Hilfe', func: 'chatbot', icon: '', msg: '', disabled: false },
    { title: 'Hilfe/Begriffs-Erklärungen', url: '/help', icon: '', disabled: false },
    { title: 'Förderinfos', func: 'gotoUrl', icon: '', disabled: false },
    { title: 'Kontakt', url: '/contact', icon: '', disabled: false },
    { title: 'Impressum', url: '/impressum', icon: '', disabled: false },
    //{ title: 'Offene Punkte', url: '/open-issues', icon: 'code-working-outline', disabled: false},
    { title: '', url: '', icon: '', disabled: false },
    { title: '', url: '', icon: '', disabled: false },
    { title: '', url: '', icon: '', disabled: false },
    { title: 'Anmelden', url: '/login', icon: 'log-in', disabled: false},
  ];

  constructor(
    private translate: TranslateService,
    private events: EventsService,
    private preferences: PreferencesService,
    private alertCtrl: AlertController,
    private router: Router
  ) {
    this.initializeApp();
  }

  async goBack() {
    console.log("hannes");
  }

  async handleDidOpen(e: Event) {
    console.log('menu handleDidOpen ..., e=', e);    
  }

  async handleWillOpen(e: Event) {
    console.log('menu handleWillOpen ..., e=', e);
  }

  async clickHandler(type: string, msg: string = '') {
    console.log('app.components.ts, clickHandler, type=', type);
    if (type === 'alert') {
      const alert = await this.alertCtrl.create({
        header: '',
        subHeader: '',
        message: msg,
        buttons: ['OK']
      });
      await alert.present();
      const result = await alert.onDidDismiss();
    } else if (type === 'gotoUrl') {
      window.open("https://www.arbeitsagentur.de/karriere-und-weiterbildung/foerderung-berufliche-weiterbildung");
    } else if (type === 'gotoCerts') {
      const token: any = await this.preferences.getString('my-token');
      console.log(token);
      window.open("https://zertifikat.sma-lo.de/SMALO-ZM/service/public/login/" + token.value);
    } else {
      this.events.publishSomeData({
        page: null,
        step: 0,
        message: type
      });
    }
  }

  async initializeApp() {
    this.translate.setDefaultLang('de');

    const token: any = await this.preferences.getString('my-token');
    console.log('token=', token);
    const user: any = await this.preferences.getObject('me');
    console.log('user=', user);

    this.activeRoute = this.router.url;

    let page: any = this.router.events.subscribe((event:Event) => {
      if(event instanceof NavigationEnd ){
        //console.log('router end url=', event.url);
        this.activeRoute = event.url;
      }
    });

    console.warn('page=', page);

    //this.preferences.setString('willbot', 'true');
    //this.preferences.setString('tempbot', 'false');

    if (token && user) {
      this.username = user['displayname'];  //user['email'];
      this.appPages = [
        { title: 'Startseite', url: '/home', icon: '', disabled: false },
        { title: 'Suche', url: '/search/blank', icon: '', disabled: false },
        { title: 'Mein Profil/Favoriten/Zertifikate', url: '/profile', icon: '', disabled: false },
        //{ title: 'Hilfe', func: 'chatbot', icon: '', disabled: false },
        { title: 'Hilfe/Begriffs-Erklärungen', url: '/help', icon: '', disabled: false },
        { title: 'Mein Lernpfad', url: '/mylearningpath', icon: '', disabled: false },
        // { title: 'Meine Zertifikate', func: 'gotoCerts', icon: '', disabled: false },
        // { title: 'Meine Favoriten (Merkliste)', url: '/myfavorites', icon: '', disabled: false },
        // { title: 'Meine Suchen', url: '/mysearches', icon: '', disabled: false },
        { title: 'Förderinfos', func: 'gotoUrl', icon: '', disabled: false },
        { title: 'Kontakt', url: '/contact', icon: '', disabled: false },
        { title: 'Impressum', url: '/impressum', icon: '', disabled: false },
        //{ title: 'Offene Punkte', url: '/open-issues', icon: 'code-working-outline', disabled: false },
        { title: '', url: '', icon: '', disabled: false },
        { title: '', url: '', icon: '', disabled: false },
        { title: 'Abmelden', url: '/logout', icon: 'log-out', disabled: false },
      ];
    }

    this.events.getObservable().subscribe((data) => {

      console.log('app.components.ts, getObservable, data received:', data);

      if (data.action === 'login') {
        this.username = data.username;
        this.appPages = [
          { title: 'Stöbern', url: '/home', icon: '', disabled: false },
          { title: 'Suche', url: '/search/blank', icon: '', disabled: false },
          { title: 'Mein Profil/Favoriten/Zertifikate', url: '/profile', icon: '', disabled: false },
          //{ title: 'Hilfe', func: 'chatbot', icon: '', disabled: false },
          { title: 'Hilfe/Begriffs-Erklärungen', url: '/help', icon: '', disabled: false },
          { title: 'Mein Lernpfad', url: '/mylearningpath', icon: '', disabled: false },
          // { title: 'Meine Zertifikate', func: 'gotoCerts', icon: '', disabled: false },
          // { title: 'Meine Favoriten (Merkliste)', url: '/myfavorites', icon: '', disabled: false },
          // { title: 'Meine Suchen', url: '/mysearches', icon: '', disabled: false },
          { title: 'Förderinfos', func: 'gotoUrl', icon: '', disabled: false },
          { title: 'Kontakt', url: '/contact', icon: '', disabled: false },
          { title: 'Impressum', url: '/impressum', icon: '' , disabled: false},
          //{ title: 'Offene Punkte', url: '/open-issues', icon: 'code-working-outline', disabled: false },
          { title: '', url: '', icon: '', disabled: false },
          { title: '', url: '', icon: '', disabled: false },
          { title: 'Abmelden', url: '/logout', icon: 'log-out', disabled: false },          
        ];
      } else if (data.action === 'logout') {
        this.username = '';
        this.appPages = [
          { title: 'Stöbern', url: '/home', icon: '', disabled: false },
          { title: 'Suche', url: '/search/blank', icon: '', disabled: false},
          //{ title: 'Hilfe', func: 'chatbot', icon: '', disabled: false },
          { title: 'Hilfe/Begriffs-Erklärungen', url: '/help', icon: '', disabled: false },
          { title: 'Förderinfos', func: 'gotoUrl', icon: '', disabled: false },
          { title: 'Kontakt', url: '/contact', icon: '', disabled: false },
          { title: 'Impressum', url: '/impressum', icon: '', disabled: false },
          //{ title: 'Offene Punkte', url: '/open-issues', icon: 'code-working-outline', disabled: false},
          { title: '', url: '', icon: '', disabled: false},
          { title: '', url: '', icon: '', disabled: false },
          { title: 'Anmelden', url: '/login', icon: 'log-in', disabled: false },          
        ];
      }
    });
  }
}
